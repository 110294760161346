<template>
  <transition name="showHeader">
    <div v-if="visible" class="header-animat">
      <a-layout-header v-if="visible" :class="[fixedHeader && 'ant-header-fixedHeader', sidebarOpened ? 'ant-header-side-opened' : 'ant-header-side-closed']" :style="{ padding: '0' }">
        <div v-if="mode === 'sidemenu'" class="header">
          <a-icon v-if="device === 'mobile'" class="trigger" :type="collapsed ? 'menu-fold' : 'menu-unfold'" @click="toggle" />
          <a-icon v-else class="trigger" :type="collapsed ? 'menu-unfold' : 'menu-fold'" @click="toggle" />
          <span class="icon-box">
            <a-dropdown>
              <a-icon :component="fileUserFill" class="trigger" />
              <a-menu slot="overlay" @click="typeClick">
                <a-menu-item key="BUSY" v-show="userStatus === 'IDLE' || userStatus === 'CALL'">
                  置忙
                </a-menu-item>
                <a-menu-item key="IDLE" v-show="userStatus === 'BUSY'">
                  空闲
                </a-menu-item>
                <a-menu-item key="OFF_LINE" v-show="userStatus === 'OFF_LINE'" disabled>
                  已离线
                </a-menu-item>
              </a-menu>
            </a-dropdown>
            <span class="tip">状态</span>
          </span>
          <span class="icon-box">
            <a-dropdown placement="bottomCenter" style="z-index: 100">
              <a-icon :component="horn" class="trigger" />
              <a-menu slot="overlay">
                <a-menu-item>
                  <div class="slider-box">
                    <p>输入声音</p>
                    <a-slider @change="change" v-model="volume" />
                  </div>
                </a-menu-item>
                <a-menu-item>
                  <p>静音</p>
                  <a-switch @change="mute" />
                </a-menu-item>
              </a-menu>
            </a-dropdown>
            <span class="tip">音量</span>
          </span>
          <span class="icon-box" @click="keepClick">
            <a-icon :component="headset" class="trigger" />
            <span class="tip">保持</span>
          </span>
          <span class="icon-box" @click="endCall">
            <a-icon :component="phoneOff" class="trigger" />
            <span class="tip">挂断</span>
          </span>
          <span class="icon-box" @click="killCall">
            <a-icon type="delete" class="trigger" />
            <span class="tip">清理</span>
          </span>
          <div class="icon-box" @click="getMessage" v-show="userInfo.seatType === 'CUSTOMER'">
            <a-icon type="bell" theme="filled" class="trigger" style="font-size: 20px" />
            <span class="tip">消息</span>
            <a-badge class="badge" :count="wait"></a-badge>
          </div>
          <audio id="remoteAudio" ref="audio" autoplay>
            <p>Your browser doesn't support HTML5 audio.</p>
          </audio>
          <audio id="ringtone" loop src="/Ring.aac"></audio>
          <span class="right">
            当前状态：
            <a-badge status="processing" :text="userStatue" :color="getStatusColor()" />
          </span>
          <user-menu></user-menu>
        </div>
        <div v-else :class="['top-nav-header-index', theme]">
          <div class="header-index-wide">
            <div class="header-index-left">
              <logo class="top-nav-header" :show-title="device !== 'mobile'" />
              <s-menu v-if="device !== 'mobile'" mode="horizontal" :menu="menus" :theme="theme" />
              <a-icon v-else class="trigger" :type="collapsed ? 'menu-fold' : 'menu-unfold'" @click="toggle" />
            </div>
            <user-menu class="header-index-right"></user-menu>
          </div>
        </div>
      </a-layout-header>
      <!--      <a-modal-->
      <!--        :centered="true"-->
      <!--        :closable="false"-->
      <!--        :keyboard="false"-->
      <!--        :maskClosable="false"-->
      <!--        :visible="phoneDia"-->
      <!--        :confirm-loading="confirmLoading"-->
      <!--        :footer="null"-->
      <!--      >-->
      <!--        <div class="dia-box">-->
      <!--          <p>{{ callerData.name }}</p>-->
      <!--          <p>{{ callerData.phone }}</p>-->
      <!--          <p>{{ callerData.sex }}</p>-->
      <!--          <a-button type="primary" @click="handleOk">接听</a-button>-->
      <!--        </div>-->
      <!--      </a-modal>-->
    </div>
  </transition>
</template>

<script>
import { Web } from 'sip.js'
import UserMenu from '../tools/UserMenu'
import SMenu from '../Menu/'
import Logo from '../tools/Logo'
import { mixin } from '@/utils/mixin'
import { phoneOff, headset, horn, fileUserFill } from '@/core/icons'
import { updateStatus, updateStatusCall, getStatus, refresh, heartbeat, killCall } from '@/api/manage/user'
import { mapGetters, mapMutations } from 'vuex'
// import { dataBind } from '@/api/home'
import Vue from 'vue'
// import { ACCESS_FS } from '@/store/mutation-types'
import { ACCESS_FS, ACCESS_TOKEN } from '@/store/mutation-types'
import '@/utils/stomp'
import modal from 'ant-design-vue/lib/modal'
import store from '@/store'
const Ring = {
  /**
   * 振铃
   */
  startRingTone: () => {
    console.log(document.getElementById('ringtone'))
    const play = document.getElementById('ringtone').play()
    play.then(() => {})
  },
  /**
   * 停止振铃
   */
  stopRingTone: () => {
    document.getElementById('ringtone').pause()
  }
}
export default {
  name: 'GlobalHeader',
  components: {
    UserMenu,
    SMenu,
    Logo
  },
  mixins: [mixin],
  props: {
    mode: {
      type: String,
      // sidemenu, topmenu
      default: 'sidemenu'
    },
    menus: {
      type: Array,
      required: true
    },
    theme: {
      type: String,
      required: false,
      default: 'dark'
    },
    collapsed: {
      type: Boolean,
      required: false,
      default: false
    },
    device: {
      type: String,
      required: false,
      default: 'desktop'
    }
  },
  computed: {
    ...mapGetters(['userInfo', 'stompClient'])
  },
  data() {
    return {
      userStatus: '',
      visible: true,
      oldScrollTop: 0,
      phoneOff,
      headset,
      horn,
      fileUserFill,
      confirmLoading: false,
      phoneDia: false,
      volume: 100,
      // domain: '192.168.1.46',
      domain: 'next.call.yiring.com',
      // 密码
      password: 'fs@yiring',
      // 坐席号
      user: '1000',
      // 外显用户名
      displayName: '1000',
      // 连线用户号
      callNumber: '15200407150',
      // 是否登录
      isLogin: false,
      // 是否注册
      registered: false,
      // 当前状态
      status: undefined,
      // 连线中 ...
      inConnection: false,
      // 重连中...
      reconnecting: undefined,
      userStatue: '等待接听',
      count: 3,
      expireTime: '',
      flag: true,
      timer: null,
      wait: null
    }
  },
  watch: {
    userStatus(n) {
      if (n === 'IDLE' || n === 'LINE') {
        this.registered = false
      } else {
        this.registered = true
      }
      // this.switchOnline()
    }
  },
  created() {
    if (this.userInfo.seatType === 'CALL') {
      this.timer = setInterval(() => {
        this.heartbeat()
      }, 15000)
      navigator.getUserMedia(
        { audio: true },
        async () => {
          await this.$notification.success({
            message: '提示',
            description: '麦克风授权成功'
          })
          this.user = Vue.ls.get(ACCESS_FS).fsAccount
          this.password = Vue.ls.get(ACCESS_FS).fsPassword
          await this.login()
          await this.switchOnline(this.userStatus === 'BUSY')
          // await this.getStatus()
        },
        error => {
          console.log('错误：', error)
        }
      )
    } else if (this.userInfo.seatType === 'CUSTOMER') {
      this.connect()
    }
    document.addEventListener('scroll', this.handleScroll, { passive: true })
  },
  methods: {
    connect() {
      this.$stomp.info = { token: this.userInfo.id, type: 'SERVICE' }
      this.$stomp.init(client => {
        console.log('初始化 STOMP')
        this.setStompClient(client)
        client.subscribe('/topic/onlineUsers', respnose => {
          this.wait = JSON.parse(respnose.body).content
        })

        // 订阅新消息
        client.subscribe('/user/chat', respnose => {
          const routePath = this.$route.path
          if (routePath !== '/delivery/ChatManage') {
            this.$notification.open({
              key: 'chat-message-notice',
              duration: 0,
              message: '新消息提醒',
              description: '您有新的对话消息，请及时查看',
              icon: <a-icon type="message" style="color: #108ee9" />,
              onClick: () => {
                this.$notification.close('chat-message-notice')
                this.$router.push({ path: '/delivery/ChatManage' })
              }
            })
            return
          }

          const vm = this.$stomp.subscribes['/user/chat']
          const data = JSON.parse(respnose.body)
          if (data.from === vm.messageData.to) {
            vm.messageList.push(data)
          }
          vm.scrollTop()
          vm.readMsg(vm.messageData.to)
        })

        // 订阅聊天消息列表
        client.subscribe('/user/chat/msgListNotice', respnose => {
          const routePath = this.$route.path
          if (routePath !== '/delivery/ChatManage') {
            return
          }

          const vm = this.$stomp.subscribes['/user/chat/msgListNotice']
          vm.contactsList = JSON.parse(respnose.body)
        })

        client.subscribe('/user/chat/disConnect', respnose => {
          // const data = JSON.parse(respnose.body)
          modal.error({
            title: '提示',
            content: '您的账户已在其他地方登录或登录信息失效，点击确认返回登录页！',
            okText: '确认',
            key: 1,
            closable: false,
            keyboard: false,
            maskClosable: false,
            onOk() {
              store.dispatch('Logout').then(() => {
                window.location.reload()
              })
            }
          })
          this.$stomp && this.$stomp.close()
        })
      })
      this.axios
        .get(`${process.env.VUE_APP_API_DELIVERY_BASE_URL}/delivery-cloud/noticeOnlineUsers`)
        .then(res => {
          if (res.status !== 200) {
            throw new Error(res.message)
          }
          this.wait = res.body
        })
        .catch(e => {})
    },
    getMessage() {
      if (this.wait > 0) {
        this.$router.push('/delivery/ChatManage')
      }
      this.axios({
        method: 'post',
        url: `${process.env.VUE_APP_API_DELIVERY_BASE_URL}/delivery-cloud/join`,
        data: {
          customerService: this.userInfo.id,
          serviceName: this.userInfo.realName
        },
        transformRequest: [
          function(data) {
            let ret = ''
            for (const it in data) {
              ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&'
            }
            ret = ret.substring(0, ret.lastIndexOf('&'))
            return ret
          }
        ],
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }).then(res => {
        this.$notification.success({
          message: '提示',
          description: res.message
        })
        // const vm = this.$stomp.subscribes['/user/chat/msgListNotice']
        // this.$nextTick(() => {
        //   console.log(vm.contactsList)
        //   vm.join(vm.contactsList.list[0].number)
        // })
      })
    },
    heartbeat() {
      const id = this.userInfo.id
      if (this.flag) {
        heartbeat({ id, times: Date.now() }).then(res => {
          this.userStatus = res.body
        })
      }
    },
    handleScroll() {
      if (!this.autoHideHeader) {
        return
      }

      const scrollTop = document.body.scrollTop + document.documentElement.scrollTop
      if (!this.ticking) {
        this.ticking = true
        requestAnimationFrame(() => {
          if (this.oldScrollTop > scrollTop) {
            this.visible = true
          } else if (scrollTop > 300 && this.visible) {
            this.visible = false
          } else if (scrollTop < 300 && !this.visible) {
            this.visible = true
          }
          this.oldScrollTop = scrollTop
          this.ticking = false
        })
      }
    },
    toggle() {
      this.$emit('toggle')
    },
    change(value) {
      this.$refs.audio.volume = value / 100
    },
    getStatus() {
      this.setLoading(true)
      return new Promise((resolve, reject) => {
        const id = this.userInfo.id
        getStatus({ id })
          .then(res => {
            if (res.status === 200) {
              console.log(res)
              this.userStatus = res.body
              this.setStatus(this.userStatus)
              resolve()
            }
          })
          .catch(reject => {})
          .finally(() => {
            this.setLoading(false)
          })
      })
    },
    async refresh() {
      await refresh().then(res => {
        if (res.status !== 200) {
          throw new Error(res.message)
        }
        Vue.ls.set(ACCESS_TOKEN, res.body.token, 7 * 24 * 60 * 60 * 1000)
      })
      await this.switchOnline()
      await this.getStatus()
    },
    async typeClick({ key }) {
      if (key !== 'OFF_LINE') {
        await this.updateStatus(key)
        await this.switchOnline(key === 'BUSY')
        // await this.getStatus()
      }
    },
    updateStatus(value) {
      console.log(value)
      const userId = this.userInfo.id
      updateStatus({ status: value, userId })
        .then(res => {
          if (res.status !== 200) {
            throw new Error(res.message)
          }
        })
        .catch(e => {
          this.$notification.error({
            message: '提示',
            description: e.message
          })
        })
    },
    dataBind(phone, callNo, retryCount = 1) {
      if (retryCount < 0) {
        return
      }

      console.log('开始触发后续接口')
      console.log('传参数据：', phone, callNo)
      let isQuestion = null
      const userId = this.userInfo.id
      if (callNo.slice(-1) === '|') {
        isQuestion = true
        callNo = callNo.slice(0, -1)
      } else {
        isQuestion = false
      }
      console.log({ userId: userId, id: callNo, isQuestion: isQuestion })
      this.$router.push({ path: '/access', query: { userId: userId, id: callNo, isQuestion: `${isQuestion}` } })

      // dataBind({ phone, callNo, userId })
      //   .then(res => {
      //     if (res.status !== 200) {
      //       throw new Error(res.message)
      //     }
      //     const { id, taskId } = res.body
      //     console.log('接口返回：', id, taskId)
      //     this.$router.push({ path: '/access', query: { id, taskId } })
      //   })
      //   .catch(e => {
      //     this.$notification.error({
      //       message: '提示',
      //       description: e.message,
      //       duration: 10,
      //       key: 'link'
      //     })
      //
      //     // 重试一次
      //     this.dataBind(phone, callNo, retryCount - 1)
      //   })
    },
    updateStatusCall() {
      updateStatusCall().then(res => {
        console.log(res)
      })
    },
    keepClick() {
      // console.log('保持通话')
      // this.dataBind('17674003620', '5ef31cc0bb809972d4e1518c')
      // const userId = this.userInfo.id
      // this.$router.push({ path: '/access', query: { userId: userId, id: '5ed7c2b13994656510d2a314' } })
    },
    getStatusColor() {
      switch (this.userStatue) {
        case '等待接听':
          return 'green'
        case '接听中':
          return 'orange'
        case '已挂断':
          return 'red'
        case '已断开':
          return 'red'
      }
      return 'gray'
    },
    initSipOptions() {
      const self = this
      const { user, displayName, password, domain } = this

      // SimpleUser delegate
      const simpleUserDelegate = {
        onServerConnect: () => {
          this.$notification.success({
            message: '提示',
            description: '已连接'
          })

          if (this.reconnecting) {
            setTimeout(this.reconnecting, 500)
          }
        },
        onServerDisconnect: error => {
          this.$notification.error({
            message: '提示',
            description: '连接已断开: ' + error
          })

          this.reconnecting = this.$message.loading('网络异常，连接已断开，正在尝试重新连接...', 0)
        },
        onRegistered: () => {
          this.$notification.success({
            message: '提示',
            description: '上线成功，您现在开始可以接听电话了'
          })

          this.updateStatus('IDLE')
          this.getStatus()
          this.registered = true
        },
        onUnregistered: () => {
          this.$notification.warn({
            message: '提示',
            description: '您处于离线状态，将不会收到来电提醒'
          })

          this.getStatus()
          this.registered = false
        },
        onCallCreated: () => {
          this.userStatue = '等待接听'
          const session = self.simpleUser.session
          const user = session.remoteIdentity.uri.user
          console.log(`[${user}] Call created`, 1111111111111111111111111111111111111, '创建')
        },
        onCallReceived: () => {
          const session = self.simpleUser.session
          const user = session.remoteIdentity.uri.user
          console.log(`[${user}] Call received`, 2222222222222222222222222222222222222222222, '收到')

          if (this.$route.name === 'access') {
            session.reject()
            this.rejectSilent = true
            return
          }
          this.userStatue = '等待接听'

          // 播放来电提示音
          Ring.startRingTone()
          const that = this
          this.confirm = this.$confirm({
            title: '来电提醒',
            content: `${user} 来电了，是否接听？`,
            okText: '接听',
            cancelText: '取消',
            onOk() {
              self.received = true
              return new Promise((resolve, reject) => {
                // 接受来电
                self.simpleUser
                  .answer()
                  .then(() => {
                    // 监听对方挂断电话
                    session.delegate.onBye = ({ request }) => {
                      console.warn('onBye', request)
                      self.$notification.error({
                        message: '提示',
                        description: `${request.from.uri.user} 挂断电话`
                      })
                      // self.$message.warn(`${request.from.uri.user} 挂断电话`, 5)
                      Ring.stopRingTone()
                    }
                    console.log('进入接入页面')
                    that.dataBind(session.remoteIdentity.uri.user, session.remoteIdentity.displayName)
                    // that.updateStatusCall()
                    Ring.stopRingTone()
                    resolve()
                  })
                  .catch(error => {
                    console.error(`[${self.simpleUser.id}] failed to answer call`, error)
                    // alert(`[${self.simpleUser.id}] Failed to answer call.\n` + error)
                    self.$notification.error({
                      message: '提示',
                      description: `对方已挂断或断开连接`
                    })
                    Ring.stopRingTone()
                    reject()
                  })
                  .finally(() => {
                    self.received = false
                  })
              }).catch(() => {
                console.log('Oops errors!')
                Ring.stopRingTone()
              })
            },
            onCancel() {
              // 拒绝来电
              session.reject()
              Ring.stopRingTone()
            }
          })
        },
        onCallAnswered: () => {
          this.updateStatus('CALL')
          this.userStatue = '接听中'
          console.log(`[${displayName}] Call answered`, 33333333333333333333333333333333333333333333, '回答')
          this.inConnection = false
          // this.$message.success('电话接通成功', 5)
          this.$notification.success({
            message: '提示',
            description: '电话接通成功'
          })
        },
        onCallHangup: () => {
          if (this.rejectSilent) {
            this.rejectSilent = false
            return
          }

          this.userStatue = '已挂断'
          if (this.$route.name === 'access') {
            this.updateStatus('TRANSACTION')
          } else {
            this.updateStatus('IDLE')
          }
          console.log(self.simpleUser)
          console.log(`[${self.simpleUser.id}] Call hangup`, 444444444444444444444444444444444, '电话已挂断')

          if (this.confirm && !this.received) {
            this.confirm.destroy()
            this.confirm = undefined
          }
          // this.$message.warn('电话已挂断', 5)
          this.$notification.error({
            message: '提示',
            description: '电话已挂断'
          })
          Ring.stopRingTone()
        },
        onCallHold: held => {
          console.log(`[${displayName}] Call hold ${held}`, 55555555555555555555555555555555555555555, '保持呼叫')
        }
      }

      // SimpleUser options
      return {
        aor: `sip:${user}@${domain}`,
        media: {
          constraints: { audio: true, video: false },
          remote: { audio: document.getElementById('remoteAudio') }
        },
        delegate: simpleUserDelegate,
        userAgentOptions: {
          logLevel: 'warn',
          displayName: user,
          authorizationUser: user,
          authorizationPassword: password,
          transportOptions: {
            traceSip: true,
            server: `wss://${domain}:4443`
            // server: `ws://${domain}:4066`
          },
          viaHost: domain,
          // hackWssInTransport: true,
          sessionDescriptionHandlerFactoryOptions: {
            peerConnectionOptions: {
              iceCheckingTimeout: 100,
              rtcConfiguration: {
                iceServers: [
                  {
                    urls: 'stun:stun.net.yiring.com:3478'
                  }
                ]
              }
            }
          },

          // 重连次数
          reconnectionAttempts: 600,
          // 重连间隔(秒)
          reconnectionDelay: 1
        }
      }
    },
    login() {
      const simpleUserOptions = this.initSipOptions()
      this.simpleUser = new Web.SimpleUser(undefined, simpleUserOptions)
      // fix: https://github.com/onsip/SIP.js/issues/791
      this.simpleUser.userAgent.contact.uri.user = this.user

      return new Promise((resolve, reject) => {
        this.simpleUser
          .connect()
          .then(() => {
            this.isLogin = true
            this.status = 'connected'
            console.log('登录成功')
            resolve()
          })
          .catch(error => {
            console.log('登录失败')
            this.status = 'disconnected'
            console.error(`[${this.simpleUser.id}] failed to connect`)
            console.error(error)
            reject()
          })
      })
    },
    switchOnline(value) {
      return new Promise((resolve, reject) => {
        if (value) {
          return this.simpleUser
            .unregister()
            .then(() => {
              resolve()
            })
            .catch(() => {
              // this.$message.error('离线失败！', 5)
              this.$notification.error({
                message: '提示',
                description: '离线失败'
              })
              reject()
            })
        } else {
          return this.simpleUser
            .register(
              {},
              {
                requestDelegate: {
                  onReject: response => {
                    console.log(response.message.statusCode, 111111111111111111111111111111111)
                    if (response.message.statusCode === 403) {
                      // this.$message.error('FS登录失败！', 5)
                      this.getStatus()
                      this.$notification.error({
                        message: '提示',
                        description: 'FS登录失败'
                      })
                    }
                  }
                }
              }
            )
            .then(() => {
              resolve()
            })
            .catch(() => {
              // this.$message.error('上线失败！', 5)
              this.$notification.error({
                message: '提示',
                description: '上线失败'
              })
              reject()
            })
        }
      })
    },
    // 开始通话
    startCall() {
      const { domain, callNumber } = this

      // 创建一个新的出站（用户代理客户端）会话
      this.simpleUser
        .call(`sip:${callNumber}@${domain}`)
        .then(() => {
          this.inConnection = true
          // this.$message.success('呼叫成功，请您耐心等待', 5)
          this.$notification.success({
            message: '提示',
            description: '呼叫成功，请您耐心等待'
          })
        })
        .catch(() => {
          // this.$message.warn('呼叫失败，请重试！', 5)
          this.$notification.error({
            message: '提示',
            description: '呼叫失败，请重试！'
          })
        })
    },
    // 结束通话
    endCall() {
      this.simpleUser
        .hangup()
        .then(() => {
          // this.$message.success('您已成功挂断电话', 5)
          this.$notification.success({
            message: '提示',
            description: '您已成功挂断电话！'
          })
        })
        .catch(error => {
          console.error(`[${this.simpleUser.id}] failed to end session`)
          console.error(error)
          // this.$message.warn('电话已处于挂断状态', 5)
          this.$notification.error({
            message: '提示',
            description: '电话已处于挂断状态'
          })
        })
    },
    // 清理通话（用户异常通话）
    killCall() {
      this.$confirm({
        title: '提示',
        content: '确认清理账户通话状态？（当您长时间不进电话时可尝试清理操作）',
        onOk: () => {
          return new Promise((resolve, reject) => {
            killCall({
              id: this.user
            })
              .then(() => {
                this.$message.success('清理成功，请稍候查看是否有新的来电...')
                resolve()
              })
              .catch(err => {
                this.$message.warning('清理失败！')
                reject(err)
              })
          }).catch(err => console.log(err))
        }
      })
    },
    ...mapMutations({
      setStatus: 'SET_STATUS',
      setLoading: 'SET_LOADING',
      setStompClient: 'SET_StompClient'
    }),
    mute(checked) {
      if (checked) {
        this.simpleUser.mute()
      } else {
        this.simpleUser.unmute()
      }
    },
    // 退出登录
    exit() {
      this.simpleUser
        .disconnect()
        .then(() => {
          this.isLogin = false
        })
        .catch(() => {})
      // this.
    }
  },
  beforeDestroy() {
    document.body.removeEventListener('scroll', this.handleScroll, true)
    clearInterval(this.timer)
    this.exit()
    this.$stomp && this.$stomp.close()
  }
}
</script>

<style lang="less">
@import '../index.less';

.header-animat {
  position: relative;
  z-index: @ant-global-header-zindex;
}
.showHeader-enter-active {
  transition: all 0.25s ease;
}
.showHeader-leave-active {
  transition: all 0.5s ease;
}
.showHeader-enter,
.showHeader-leave-to {
  opacity: 0;
}
.slider-box {
  width: 200px;
}
.dia-box {
  text-align: center;
}
.icon-box {
  position: relative;
  width: 68px;
  height: 64px;
}
.badge {
  position: absolute;
  bottom: 40px;
  left: 40px;
}
.icon-box i {
  z-index: 100;
}
.tip {
  position: absolute;
  cursor: pointer;
  left: 50%;
  bottom: 6px;
  transform: translateX(-50%);
  line-height: normal !important;
}
.header {
  display: flex;
}
.right {
  margin-left: auto;
}
</style>
