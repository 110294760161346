import { axios } from '@/utils/request'

const basic = '/manage/user'
const api = {
  // 分页查询用户信息
  findUserList: basic + '/find',
  // 查询用户详情
  getUserDetail: basic + '/detail',
  // 修改用户信息
  updateUser: basic + '/update',
  // 添加用户
  addUser: basic + '/add',
  // 删除用户
  deleteUser: basic + '/deleted',
  // 重置密码
  resetPwd: basic + '/resetPwd',
  // 修改用户状态
  updateStatus: basic + '/updateStatus',
  // 获取用户状态
  getStatus: basic + '/getStatus',
  updateStatusCall: basic + '/updateStatusCall',
  // 更新token
  refresh: 'manage/auth/refresh',
  // 检测重复
  dupAccount: basic + '/dupAccount',
  // 清理通话
  killCall: basic + '/killCall'
}

export function findUserList(parameter) {
  return axios({
    url: api.findUserList,
    method: 'get',
    params: parameter
  })
}

export function getUserDetail(parameter) {
  return axios({
    url: api.getUserDetail,
    method: 'get',
    params: parameter
  })
}

export function addUser(parameter) {
  return axios({
    url: api.addUser,
    method: 'post',
    data: parameter
  })
}

export function updateUser(parameter) {
  return axios({
    url: api.updateUser,
    method: 'post',
    data: parameter
  })
}

export function deleteUser(parameter) {
  return axios({
    url: api.deleteUser,
    method: 'delete',
    params: parameter
  })
}

export function resetPwd(parameter) {
  return axios({
    url: api.resetPwd,
    method: 'get',
    params: parameter
  })
}
export function refreshOnline() {
  return axios({
    url: '/manage/auth/refreshOnline',
    method: 'post'
  })
}
export function heartbeat(params) {
  return axios({
    url: '/manage/user/heartbeat',
    method: 'get',
    params: params
  })
}
export function signOut() {
  return axios({
    url: '/manage/auth/signOut',
    method: 'get'
  })
}
export function updateStatus(params) {
  return axios({
    url: api.updateStatus,
    method: 'get',
    params: params
  })
}
export function getStatus(params) {
  return axios({
    url: api.getStatus,
    params: params,
    method: 'get'
  })
}
export function updateStatusCall() {
  return axios({
    url: api.updateStatusCall,
    method: 'get'
  })
}
export function refresh() {
  return axios({
    url: api.refresh,
    method: 'post'
  })
}
export function dupAccount(params) {
  return axios({
    url: api.dupAccount,
    method: 'get',
    params: params
  })
}

export function killCall(parameter) {
  return axios({
    url: api.killCall,
    method: 'post',
    data: parameter
  })
}
