// with polyfills
import 'core-js/stable'
import 'regenerator-runtime/runtime'

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/'
import { VueAxios } from './utils/request'
// import * as Sentry from '@sentry/browser'
// import * as Integrations from '@sentry/integrations'

// mock
// WARNING: `mockjs` NOT SUPPORT `IE` PLEASE DO NOT USE IN `production` ENV.
// import './mock'

import bootstrap from './core/bootstrap'
import './core/lazy_use'
import './permission' // permission control
import './utils/filter' // global filter
import './components/global.less'

Vue.config.productionTip = false
Vue.prototype.BASE_URL = process.env.VUE_APP_API_BASE_URL

// process.env.NODE_ENV === 'production' &&
//   Sentry.init({
//     dsn: 'https://ef8cfde49b1f448f8ca7d24f7761916c@sentry.ifzm.cn/2',
//     integrations: [new Integrations.Vue({ Vue, attachProps: true })],
//     release: 'call@0.0.3',
//     logErrors: true
//   })

// mount axios Vue.$http and this.$http
Vue.use(VueAxios)

new Vue({
  router,
  store,
  created: bootstrap,
  render: h => h(App)
}).$mount('#app')
