<template>
  <div class="user-wrapper">
    <div class="content-box">
      <!-- <a href="https://pro.loacg.com/docs/getting-started" target="_blank">
        <span class="action">
          <a-icon type="question-circle-o"></a-icon>
        </span>
      </a>
      <notice-icon class="action"/> -->
      <a-dropdown>
        <span class="action ant-dropdown-link user-dropdown-menu">
          <a-avatar class="avatar" size="small" src="/avatar2.jpg"/>
          <span>{{ nickname }}</span>
        </span>
        <a-menu slot="overlay" class="user-dropdown-menu-wrapper">
          <a-menu-item key="1">
            <a href="javascript:;" @click="handleLogout">
              <a-icon type="logout"/>
              <span>退出登录</span>
            </a>
          </a-menu-item>
          <a-menu-item key="2">
            <a href="javascript:;" @click="changePwd">
              <a-icon type="logout"/>
              <span>修改密码</span>
            </a>
          </a-menu-item>
        </a-menu>
      </a-dropdown>
      <a-modal
        title="修改密码"
        :maskClosable="false"
        v-model="visible"
        @ok="editHandleOk"
        @cancel="editHandleCancel"
        width="550px"
        class="edit-user-component"
      >
        <a-form-model
          ref="ruleForm"
          :model="form"
          :rules="rules"
          :confirmLoading="confirmLoading"
          :label-col="{span:4}"
          :wrapper-col="{span:20}"
        >
          <a-form-model-item label="原密码" prop="password">
            <a-input v-model="form.password" type="password"/>
          </a-form-model-item>
          <a-form-model-item label="新密码" prop="newPassword">
            <a-input v-model="form.newPassword" type="password"/>
          </a-form-model-item>
        </a-form-model>
      </a-modal>
    </div>
  </div>
</template>

<script>
// import NoticeIcon from '@/components/NoticeIcon'
import { mapActions, mapGetters } from 'vuex'
import { updatePassword } from '@/api/login'
export default {
  name: 'UserMenu',
  components: {
    // NoticeIcon
  },
  computed: {
    ...mapGetters(['nickname', 'avatar'])

  },
  data () {
    return {
      visible: false,
      confirmLoading: false,
      form: {
        password: '',
        newPassword: ''
      },
      rules: {
        password: [
          { required: true, message: '原密码密码不能为空', trigger: 'blur' }
        ],
        newPassword: [
          { required: true, message: '新密码不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    ...mapActions(['Logout']),
    handleLogout () {
      this.$confirm({
        title: '提示',
        content: '真的要退出登录吗 ?',
        onOk: () => {
          return this.Logout({}).then(() => {
            setTimeout(() => {
              window.location.reload()
            }, 16)
          }).catch(err => {
            this.$message.error({
              title: '错误',
              description: err.message
            })
          })
        },
        onCancel () {
        }
      })
      this.$stomp && this.$stomp.close()
    },
    changePwd () {
      this.visible = true
    },
    editHandleOk () {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.confirmLoading = true
          updatePassword(this.form).then(res => {
            if (res.status !== 200) {
              this.$notification.error({
                message: '提示',
                description: res.message
              })
              return
            }
            this.$notification.success({
              message: '提示',
              description: res.message
            })
            this.$refs.ruleForm.resetFields()
            this.Logout({}).then(() => {
              this.$notification.success({
                message: '提示',
                description: '密码已修改，自动跳转登录页重新登录'
              })
              setTimeout(() => {
                window.location.reload()
              }, 2000)
            }).catch(err => {
              this.$message.error({
                title: '错误',
                description: err.message
              })
            })
          }).catch((e) => {
            this.$notification.error({
              message: '提示',
              description: e.message
            })
          }).finally(() => {
            this.confirmLoading = false
          })
        } else {
          return false
        }
      })
    },
    editHandleCancel () {
      this.$refs.ruleForm.resetFields()
      this.visible = false
    }
  }
}
</script>
