<template>
  <div class="logo">
    <router-link :to="{ path: '/' }">
      <img src="~@/assets/icons/cloud-call.svg" style="height: 30px; width: 30px" alt="logo" />
      <h1 v-if="showTitle">{{ title }}</h1>
    </router-link>
  </div>
</template>

<script>
// import LogoSvg from '@/assets/hntq.logo.png'

export default {
  name: 'Logo',
  components: {
    // LogoSvg
  },
  props: {
    title: {
      type: String,
      default: '云呼系统',
      required: false
    },
    showTitle: {
      type: Boolean,
      default: true,
      required: false
    }
  }
}
</script>
