const api = {
  login: '/manage/auth/login/account',
  Logout: '/auth/logout',
  ForgePassword: '/auth/forge-password',
  Register: '/auth/register',
  twoStepCode: '/auth/2step-code',
  SendSms: '/account/sms',
  SendSmsErr: '/account/sms_err',
  // get my info
  // UserInfo: '/user/info'
  UserInfo: '/manage/user/getUserDetail',
  findPermissions: '/manage/user/findPermissions'
}
export default api
