import Vue from 'vue'
import { login, getInfo } from '@/api/login'
import { signOut } from '@/api/manage/user'
import { ACCESS_TOKEN, ACCESS_FS } from '@/store/mutation-types'
import { welcome } from '@/utils/util'

const user = {
  state: {
    token: '',
    name: '',
    welcome: '',
    avatar: '',
    roles: [],
    info: {},
    status: '',
    loading: false,
    wait: '',
    stompClient: null
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_NAME: (state, { name, welcome }) => {
      state.name = name
      state.welcome = welcome
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },
    SET_INFO: (state, info) => {
      state.info = info
    },
    SET_STATUS: (state, status) => {
      state.status = status
    },
    SET_LOADING: (state, loading) => {
      state.loading = loading
    },
    SET_WAIT: (state, wait) => {
      state.wait = wait
    },
    SET_StompClient: (state, stompClient) => {
      state.stompClient = stompClient
    }
  },

  actions: {
    // 登录
    Login ({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        login(userInfo).then(response => {
          if (response.status !== 200) {
            throw new Error(response.message)
          }
          const result = response.body
          const fs = { fsAccount: result.fsAccount, fsPassword: result.fsPassword }
          Vue.ls.set(ACCESS_TOKEN, result.token, 7 * 24 * 60 * 60 * 1000)
          Vue.ls.set(ACCESS_FS, fs, 7 * 24 * 60 * 60 * 1000)
          commit('SET_TOKEN', result.token)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 获取用户信息
    GetInfo ({ commit }) {
      return new Promise((resolve, reject) => {
        getInfo().then(response => {
          const result = response.body
          const roles = result.managerRoles
          if (roles && roles.length > 0) {
            // role.permissions = result.role.permissions
            // role.permissions.map(per => {
            //   if (per.actionEntitySet != null && per.actionEntitySet.length > 0) {
            //     const action = per.actionEntitySet.map(action => { return action.action })
            //     per.actionList = action
            //   }
            // })
            // role.permissionList = role.permissions.map(permission => { return permission.permissionId })
            commit('SET_ROLES', roles)
            commit('SET_INFO', result)
          } else {
            reject(new Error('用户无权限，请联系管理员'))
          }

          commit('SET_NAME', { name: result.realName, welcome: welcome() })
          commit('SET_AVATAR', '')

          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 登出
    Logout ({ commit, state }) {
      return new Promise((resolve) => {
        signOut().then(res => {
          console.log(res)
          if (res.code !== 200) {
            commit('SET_TOKEN', '')
            commit('SET_ROLES', [])
            Vue.ls.remove(ACCESS_TOKEN)
            resolve()
          }
        })
        // commit('SET_TOKEN', '')
        // commit('SET_ROLES', [])
        // Vue.ls.remove(ACCESS_TOKEN)
        // resolve()
        // logout(state.token).then(() => {
        //   resolve()
        // }).catch(() => {
        //   resolve()
        // }).finally(() => {
        //   commit('SET_TOKEN', '')
        //   commit('SET_ROLES', [])
        //   Vue.ls.remove(ACCESS_TOKEN)
        // })
      })
    }

  }
}

export default user
