import Vue from 'vue'
import Stomp from 'stompjs'
import debounce from 'lodash.debounce'
// import { message } from 'ant-design-vue/es'
import notification from 'ant-design-vue/es/notification'

const BASE_URL = process.env.VUE_APP_API_DELIVERY_BASE_URL

const connect = cb => {
  if (stomp.client) {
    stomp.client.disconnect()
    stomp.client = undefined
  }

  const client = Stomp.client(`${BASE_URL.replace(/http/, 'ws')}/delivery-cloud/endpoint`)
  client.heartbeat.outgoing = 10000
  client.heartbeat.incoming = 10000
  client.debug = null
  client.connect(
    stomp.info,
    () => {
      stomp.client = client
      setTimeout(() => {
        notification.success({
          key: 'stomp.init',
          placement: 'bottomRight',
          message: '提示信息',
          description: '已开启在线客服聊天'
        })
      }, 60)

      cb && cb(client)
    },
    debounce(() => {
      notification.warning({
        key: 'stomp.init',
        duration: null,
        placement: 'bottomRight',
        message: '网络异常',
        description: '聊天已关闭，正在尝试重新建立连接...'
      })
      connect(cb)
    }, stomp.retryInterval)
  )
}

const stomp = {
  // 断线重连时间间隔
  retryInterval: 3000,

  // 客户端连接对象
  client: undefined,

  // 缓存用户Token
  token: undefined,

  // 用户信息
  info: {},

  // 订阅对象
  subscribes: {},

  // 初始化连接
  init: connect,

  // 关闭连接
  close: () => {
    if (stomp.client) {
      stomp.client.disconnect()
    }
  }
}

// 注册全局STOMP对象 Vue.$stomp
Object.defineProperties(Vue.prototype, {
  $stomp: {
    get: function get() {
      return stomp
    }
  }
})
