// eslint-disable-next-line
import Vue from 'vue'
import * as loginService from '@/api/login'
// eslint-disable-next-line
import { BasicLayout, RouteView, BlankLayout, PageView } from '@/layouts'

// 前端路由表
const constantRouterComponents = {
  // 基础页面 layout 必须引入
  BasicLayout: BasicLayout,
  // BlankLayout: BlankLayout,
  // RouteView: RouteView,
  // PageView: PageView,
  // 你需要动态引入的页面组件
  manage: PageView,
  appManage: PageView,
  outbound: PageView,
  template: PageView,
  delivery: PageView,
  questionnaire: PageView,
  external: PageView,
  home: () => import('@/views/home/Home'),
  access: () => import('@/views/Access'),
  golddigger: () => import('@/views/golddigger/Placeanorder'),
  platformUserManage: () => import('@/views/manage/user/PlatformUserManageView'),
  platformPermissionManage: () => import('@/views/manage/permission/PermissionManageView'),
  platformRoleManage: () => import('@/views/manage/role/RoleManageView'),
  OrganizationManage: () => import('@/views/manage/organization/OrganizationManageView'),
  projectManagement: () => import('@/views/outbound/project/ProjectManagementView'),
  explicitManage: () => import('@/views/template/explicit/ExplicitView'),
  patterManage: () => import('@/views/template/patter/PatterView'),
  templateMange: () => import('@/views/template/template/TemplateView'),
  taskManage: () => import('@/views/outbound/task/TaskManageView'),
  callManage: () => import('@/views/outbound/call/CallView'),
  messageManage: () => import('@/views/outbound/message/MessageView'),
  marketMange: () => import('@/views/outbound/market/MarketView'),
  onlineDataManage: () => import('@/views/delivery/OnlineDataView'),
  PhoneManage: () => import('@/views/delivery/PhoneManageView'),
  ChatManage: () => import('@/views/delivery/ChatView'),
  questionnaireTaskManage: () => import('@/views/questionnaire/questionnaireTask/questionnaireTaskView'),
  questionnaireRecordManage: () => import('@/views/questionnaire/questionnaireRecord/questionnaireRecordView'),
  ExternalData: () => import('@/views/external/data/ExternalDataView'),
  ExternalDataPush: () => import('@/views/external/push/ExternalDataPushView'),
  IvrTaskData: () => import('@/views/ivr/task/IvrTaskDataView'),
  IvrPhoneData: () => import('@/views/ivr/phone/IvrPhoneDataView')
}

// 前端未找到页面路由（固定不用改）
const notFoundRouter = {
  path: '*',
  redirect: '/404',
  hidden: true
}

// 根级菜单
const rootRouter = {
  key: '',
  name: 'index',
  path: '/',
  component: 'BasicLayout',
  redirect: '/home',
  meta: {
    title: '首页'
  },
  children: [
    {
      name: 'home',
      path: '/home',
      component: 'home',
      meta: {
        title: '首页',
        show: true,
        icon: 'home'
      }
    },
    {
      name: 'access',
      path: '/access',
      component: 'access',
      meta: {
        title: '电话接入',
        show: false
      }
    }
  ]
}

/**
 * 动态生成菜单
 * @param token
 * @returns {Promise<Router>}
 */
export const generatorDynamicRouter = token => {
  return new Promise((resolve, reject) => {
    loginService
      .getCurrentUserNav(token)
      .then(res => {
        const result = res.body.permissions
        if (result.length === 0) {
          throw new Error('用户角色无权限，请联系管理员')
        }
        const menuNav = []
        const childrenNav = []
        //      后端数据, 根级树数组,  根级 PID
        console.log('result', result)
        listToTree(result, childrenNav, 0)
        rootRouter.children = [...rootRouter.children, ...childrenNav]
        menuNav.push(rootRouter)
        console.log('menuNav', menuNav)
        const routers = generator(menuNav)
        routers.push(notFoundRouter)
        console.log('routers', routers)

        // 注入全局函数，实现按钮级别权限控制
        const actions = result.filter(item => item.method === 'action').map(item => item.name)
        Vue.prototype.$checkPermission = permission => {
          return actions.includes(permission)
        }

        resolve(routers)
      })
      .catch(err => {
        reject(err)
      })
  })
}

/**
 * 格式化树形结构数据 生成 vue-router 层级路由表
 *
 * @param routerMap
 * @param parent
 * @returns {*}
 */
export const generator = (routerMap, parent) => {
  return routerMap.map(item => {
    const { title, show, hideChildren, hiddenHeaderContent, target, icon } = item.meta || {}
    const currentRouter = {
      // 如果路由设置了 path，则作为默认 path，否则 路由地址 动态拼接生成如 /dashboard/workplace
      // path: item.path || `${parent && parent.path || ''}/${item.key}`,
      path: item.url || item.path,
      // 路由名称，建议唯一
      name: item.name || item.key || '',
      // 该路由对应页面的 组件 :方案1
      component: constantRouterComponents[item.component || item.key] || PageView,
      // 该路由对应页面的 组件 :方案2 (动态加载)
      // component: constantRouterComponents[item.component || item.key] || () => import(`@/views/${item.component}`),

      // meta: 页面标题, 菜单图标, 页面权限(供指令权限用，可去掉)
      meta: {
        title: title,
        icon: icon || undefined,
        hiddenHeaderContent: hiddenHeaderContent,
        target: target,
        permission: item.name
      }
    }
    // 是否设置了隐藏菜单
    if (show === false) {
      currentRouter.hidden = true
    }
    // 是否设置了隐藏子菜单
    if (hideChildren) {
      currentRouter.hideChildrenInMenu = true
    }
    // 为了防止出现后端返回结果不规范，处理有可能出现拼接出两个 反斜杠
    if (currentRouter.path && !currentRouter.path.startsWith('http')) {
      currentRouter.path = currentRouter.path.replace('//', '/')
    }
    // 重定向
    item.redirect && (currentRouter.redirect = item.redirect)
    // 是否有子菜单，并递归处理
    if (item.children && item.children.length > 0) {
      // Recursion
      currentRouter.children = generator(item.children, currentRouter)
    }
    return currentRouter
  })
}

/**
 * 数组转树形结构
 * @param list 源数组
 * @param tree 树
 * @param parentId 父ID
 */
const listToTree = (list, tree, parentId) => {
  list.forEach(item => {
    // 判断是否为父级菜单
    if (item.pid === parentId && item.method === 'menu') {
      const child = {
        ...item,
        key: item.key || item.name,
        meta: { title: item.description, icon: item.icon, show: !item.hidden },
        children: []
      }
      // 迭代 list， 找到当前菜单相符合的所有子菜单
      listToTree(list, child.children, item.id)
      // 删掉不存在 children 值的属性
      if (child.children.length <= 0) {
        delete child.children
      }
      // 加入到树中
      tree.push(child)
    }
  })
}
