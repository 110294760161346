/* eslint-disable space-before-function-paren */
import Vue from 'vue'
import axios from 'axios'
import store from '@/store'
import notification from 'ant-design-vue/es/notification'
import modal from 'ant-design-vue/es/modal'
// import * as Sentry from '@sentry/browser'
import { VueAxios } from './axios'
import { ACCESS_TOKEN } from '@/store/mutation-types'
import fileDownload from 'js-file-download'

// 创建 axios 实例
const service = axios.create({
  // baseURL: process.env.VUE_APP_API_BASE_URL, // api base_url
  // baseURL: isProd ? 'http://192.168.1.120:8000' : '/',
  baseURL: process.env.VUE_APP_API_BASE_URL,
  timeout: 8000 // 请求超时时间
})
let flag = true
const err = error => {
  if (error.response) {
    const data = error.response.data
    const token = Vue.ls.get(ACCESS_TOKEN)
    if (error.response.status === 403) {
      notification.error({
        message: 'Forbidden',
        description: data.message
      })
    }
    if (error.response.status === 401 && !(data.result && data.result.isLogin)) {
      this.$confirm({
        title: '提示',
        content: '您的账户已在其他地方登录或登录信息失效，点击确认返回登录页！',
        okText: '确认',
        onOk() {
          if (token) {
            store.dispatch('Logout').then(() => {
              window.location.reload()
            })
          }
        }
      })
    }
  } else {
    // const url = error.config.url
    // if (!url.includes('user/heartbeat') && !url.includes('index/show')) {
    //   notification.error({
    //     message: '错误信息',
    //     description: `异常请求: ${url} 错误消息：${error.message}`
    //   })
    // }
  }

  // 捕获超时异常
  if (error.message.includes('timeout')) {
    // 上报超时的请求信息
    const config = {
      user: store.getters.nickname,
      url: error.config.url,
      method: error.config.method,
      params: error.config.params,
      data: error.config.data,
      error: error.message
    }
    console.warn(config)
    // Sentry.captureException(new Error(JSON.stringify(config)))
  }

  // 上报所有请求异常
  // Sentry.captureException(error)

  console.error(error.message, error.config)
  return Promise.reject(error)
}

// request interceptor
service.interceptors.request.use(config => {
  const token = Vue.ls.get(ACCESS_TOKEN)
  if (token) {
    config.headers['Authorization'] = 'Bearer ' + token // 让每个请求携带自定义 token 请根据实际情况自行修改
  }

  return config
}, err)

// response interceptor
service.interceptors.response.use(response => {
  if (response.data.status === 401 && flag) {
    flag = false
    modal.error({
      title: '提示',
      content: '您的账户已在其他地方登录或登录信息失效，点击确认返回登录页！',
      okText: '确认',
      key: 1,
      closable: false,
      keyboard: false,
      maskClosable: false,
      onOk() {
        store.dispatch('Logout').then(() => {
          window.location.reload()
        })
      }
    })
  }

  // 处理文件下载
  const { data } = response
  if (response.config.download && response.config.responseType === 'blob' && data) {
    const disposition = response.headers['content-disposition']
    if (!disposition) {
      return Promise.reject(response)
    }

    const fileName = decodeURIComponent(disposition.split('=')[1])
    fileDownload(data, fileName)
    return
  }

  return response.data
}, err)

const installer = {
  vm: {},
  install(Vue) {
    Vue.use(VueAxios, service)
  }
}

export { installer as VueAxios, service as axios }
